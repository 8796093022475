// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-template-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-pages-404-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktywne-wyjazdy-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/aktywne-wyjazdy.js" /* webpackChunkName: "component---src-pages-aktywne-wyjazdy-js" */),
  "component---src-pages-index-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-na-zamowienie-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/na-zamowienie.js" /* webpackChunkName: "component---src-pages-na-zamowienie-js" */),
  "component---src-pages-pielgrzymki-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/pielgrzymki.js" /* webpackChunkName: "component---src-pages-pielgrzymki-js" */),
  "component---src-pages-swieta-i-festiwale-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/swieta-i-festiwale.js" /* webpackChunkName: "component---src-pages-swieta-i-festiwale-js" */),
  "component---src-pages-wolne-terminy-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/wolne-terminy.js" /* webpackChunkName: "component---src-pages-wolne-terminy-js" */),
  "component---src-pages-wypoczynek-i-relaks-js": () => import("/home/a/Dokumenty/BiuroPodrozy-Gliwice/src/pages/wypoczynek-i-relaks.js" /* webpackChunkName: "component---src-pages-wypoczynek-i-relaks-js" */)
}

